<template>
	<div>
        <v-img
  max-height="400"
  contain
  class="mb-6"
  src="/img/404_scaled.svg"
></v-img>
<div class="text-h2 text-center display-3 mb-8 basil--text">
        Vi fant ikke {{title}} du lette etter...
    </div>
	</div>
</template>
<script>
	export default {
        data:()=>({
            title:'Siden'
        }),
        metaInfo: {
            // override the parent template and just use the above title only
            title: '404 Ikke funnet',
            // all titles will be injected into this template
            titleTemplate: '%s | Brukmat'
        },
        created () {
          // fetch the data when the view is created and the data is
          // already being observed
          this.showError();
        },
        methods : {
            showError: function(){
                let title = this.$route.params.statement;
                if(title !== undefined) {
                    this.title = title+"en";
                }

            }
        }
    }
</script>